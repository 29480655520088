export async function getMyClubs() {
  return [
    {
      name: '타박네',
      description: '중앙대학교 중앙동아리 국예술연구회',
      imageUrl: 'https://i.imgur.com/gkk83wr.png',
    },
    {
      name: '푸앙아리',
      description: '중앙대학교 중앙동아리 푸앙이연구회',
      imageUrl: 'https://i.imgur.com/d56CAWR.png',
    },
    {
      name: '타박네',
      description: '중앙대학교 중앙동아리 국예술연구회',
      imageUrl: 'https://i.imgur.com/gkk83wr.png',
    },
    {
      name: '푸앙아리',
      description: '중앙대학교 중앙동아리 푸앙이연구회',
      imageUrl: 'https://i.imgur.com/d56CAWR.png',
    },
  ];
}
